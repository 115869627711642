const theme = {
  colors: {
    grey: "#a0a0a0",
    greyLight: "#eee",
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default theme;
